'use client'

import * as Sentry from '@sentry/nextjs'
import type { Metadata } from 'next'
import { useEffect } from 'react'
import { ErrorPage } from '../features/error/error-page.server'

export const metadata: Metadata = {
  title: 'Error',
  robots: { index: false },
}

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
}) {
  useEffect(() => {
    Sentry.captureException(error)
  }, [error])

  return (
    <ErrorPage
      statusCode={500}
      message="There was an error on our side. Please try refreshing the page."
    />
  )
}
